import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  Input,
  message,
  DatePicker,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import {
  getMonthNameFromStringDate,
  getMonthIndex,
  getDateFromMonth,
  dateFormat,
  monthNames,
} from '../../utils/dateUtils';

const ColorMaster = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  // Edit Data for editing existing record
  const [editData, setEditData] = useState(null);
  const [colorFamilyList, setColorFamilyList] = useState([]);

  const [isLoading, setIsLoading] = useState(null);
  const authToken = getToken();
  const [Data, setData] = useState([]);
  const [dataform] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  //options for form

  const [filterCloumnData, setFilterCloumnData] = useState([]);
  const [filterInfo, setFilterInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState('');

  //filter global
  const [searchText, setSearchText] = useState('');
  const [filterScope, setFilterScope] = useState('all');
  let [filteredData, setFilteredData] = useState([]);

  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);
  const filterScopeOptionsArray = [
    'all',
    'subColorName',
    'designColorCode',
    'discription',

    // Disabled: no need to map supplier to fabric
    // 'suppliers',
  ];

  let data1 = [];

  const fetchData = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/sub-colors?populate[color_family][populate]=true&sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const responseData = await response.json();
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        // return;
        data1 = responseData.data.map((entry) => {
          console.log(
            responseData,
            entry.attributes?.launch_master?.data,
            'indent check'
          );

          let colorFamilyObj = {};
          if (entry.attributes?.color_family?.data) {
            const colorFamilyObject = entry.attributes?.color_family?.data;
            console.log(colorFamilyObject, 'check last Color Family');
            colorFamilyObj = {
              colorFamilyRefId: colorFamilyObject.id,
              colorName: colorFamilyObject.attributes.colorName,
              description: colorFamilyObject.attributes.description,
            };
          }

          return {
            key: entry.id,
            id: entry.id,
            subColorName: entry.attributes.subColorName,
            discription: entry.attributes.discription,
            hexCode: entry.attributes.hexCode,
            designColorCode: entry.attributes.designColorCode,
            color_family: colorFamilyObj,
          };
        });

        console.log('original Data:', responseData);
        console.log('current Data after modification:', data1);

        setData(data1);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchColorFamilyList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/color-families?sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          colorName: entry.attributes.colorName,
          description: entry.attributes.description,
        };
      });

      setColorFamilyList(optionsData2);
      console.log('original option Data:', optionsData);
      console.log('modified Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchData(authToken);
      fetchColorFamilyList(authToken);
      // Disabled: no need to map supplier to fabric
    }
  }, []);

  // for opening add or edit form with data or change in the reference of data form when form is closed or page is reloaded
  useEffect(() => {
    //dataform data
    console.log(editData);
    dataform.setFieldsValue(editData);
  }, [editData, dataform]);

  // show the modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  // hide and reset add/edit form inside modal
  const handleOk = () => {
    dataform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  // hide and reset add/edit form inside modal
  const handleCancel = () => {
    dataform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  //add record to database table by api call
  const addNewRecord = async (data) => {
    setSaving(true);

    //add or removing extra data from values from formData object
    const values = {
      ...data,
    };

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);
    // return;
    //editId will be present only in case of edit mode
    console.log('data edit:', data.data.editId);
    // return false;

    if (data?.data?.editId) {
      console.log('edit id :', data?.data?.editId);
      // update the record
      try {
        const response = await fetch(`${API}/sub-colors/${data.data.editId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Update Record.');
        } else {
          message.success('Data saved successfully!');

          fetchData(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        dataform.resetFields();
        setEditData(null);
        setIsModalOpen(false);
      }
    } else {
      // create a new record in the database table
      console.log('edit id :', data.editId); //should be falsy

      try {
        const response = await fetch(`${API}/sub-colors/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Record.');
        } else {
          message.success('Data saved successfully!');
          //
          fetchData(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        dataform.resetFields();
        setEditData(null);
        setIsModalOpen(false);
      }
    }
  };
  //end function addNewRecord

  const columns = [
    {
      key: 'subColorName',
      title: 'Color Name',
      dataIndex: 'subColorName',
    },

    {
      key: 'discription',
      title: 'Discription',
      dataIndex: 'discription',
    },
    // {
    //   key: 'hexCode',
    //   title: 'Hex Code',
    //   dataIndex: 'hexCode',
    // },
    {
      key: 'designColorCode',
      title: 'Design Color Code',
      dataIndex: 'designColorCode',
    },
    // {
    //   key: 'color_family',
    //   title: 'Color Family',
    //   render: (_, record) => {
    //     console.log(record, 'in color family column');
    //     return record?.color_family?.colorName ?? 'Not Found';
    //   },
    // },
    {
      key: 'action',
      title: 'Actions',

      render: (_, record) => {
        return (
          <div>
            <div className="ActionColumn">
              <EditOutlined
                style={{ color: 'black' }}
                onClick={() => Edit(record)}
              />
              <Button
                icon={
                  <DeleteOutlined style={{ color: 'red', marginRight: 8 }} />
                }
                onClick={() => Delete(record)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const deleteRecord = async (recordID) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/sub-colors/${recordID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responseData = await response.json();
      console.log(responseData);

      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        fetchData(getToken());
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const Delete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this',
      onOk: () => {
        deleteRecord(record.id);
      },
    });
  };

  const Edit = (record) => {
    console.log(record, '419');

    // set data for add or edit form
    setEditData({
      subColorName: record.subColorName,
      designColorCode: record.designColorCode,
      discription: record.discription,
      color_family: record.color_family.colorFamilyRefId,
      editId: record.id, // its important for edit functionality
    });
    showModal(); // after setting the data for the edit form show the modal
  };

  const handleTableChange = (pagination, filters, sorter) => {
    console.log('in handle table change', pagination, filters, sorter);
    const { order, columnKey } = sorter;
    setFilterInfo(filters);
    setSortedInfo({ columnKey, order });
  };

  //reset table
  const resetTable = () => {
    setSortedInfo({});
    setFilterInfo({});
    setSearchText('');
    setFilterScope('all');
    setAlreadySelectedRows([]);
    fetchData(authToken);
  };

  const handleChangeSearch = (e) => {
    setSearchText(e.target.value);

    if (e.target.value == '') {
      fetchData(authToken);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    if (searchText == '') {
      fetchData(authToken);
      setFilteredData([]);
    } else {
      filterGlobalSearch();
    }
  }, [searchText, filterScope]);

  const colorFamilyFilterFunciton = (value) => {
    let flag = false;
    console.log(value, 'inside color family filter');
    return value?.color_family?.colorName
      ?.toLowerCase()
      .includes(searchText?.trim().toLowerCase());
  };

  // : on search button click
  const filterGlobalSearch = () => {
    let filteredDataTemp = [];
    if (filterScope == 'all') {
      filteredDataTemp = Data.filter((value) => {
        return (
          value?.subColorName
            ?.toLowerCase()
            ?.includes(searchText?.toLowerCase()) ||
          value?.designColorCode
            ?.toLowerCase()
            ?.includes(searchText?.toLowerCase()) ||
          value?.discription
            ?.toLowerCase()
            ?.includes(searchText?.toLowerCase()) ||
          colorFamilyFilterFunciton(value)
        );
      });
    } else if (filterScope == 'color_family') {
      filteredDataTemp = Data.filter((value) => {
        console.log('searchValue:', searchText, value);
        return colorFamilyFilterFunciton(value);
      });
    } else if (filterScope == 'subColorName') {
      filteredDataTemp = Data.filter((value) => {
        return value?.subColorName
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase());
      });
    } else if (filterScope == 'designColorCode') {
      filteredDataTemp = Data.filter((value) => {
        return value?.designColorCode
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase());
      });
    } else if (filterScope == 'discription') {
      filteredDataTemp = Data.filter((value) => {
        return value?.discription
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase());
      });
    }

    console.log(filteredDataTemp, 'filtered data', filterScope, searchText);
    if (filteredDataTemp.length > 0) {
      setFilteredData(filteredDataTemp);
    } else {
      filteredDataTemp.push({
        key: 0,
        id: 0,
        subColorName: 'Not Found',
        discription: 'Not Found',
        designColorCode: 'Not Found',
        color_family: {},
      });
      setFilteredData(filteredDataTemp);
    }
  };

  const handleFilterScopeChange = (value) => {
    console.log('setFilterScope', value);
    setFilterScope(value);
  };

  const getFilterOptionArray = (options) => {
    return options.map((option) => {
      return { value: option, label: option };
    });
  };

  const filterOptionArray = [...getFilterOptionArray(filterScopeOptionsArray)];

  const colorFamilyListOptions = colorFamilyList.map((color) => {
    return {
      label: color.colorName,
      value: color.id,
    };
  });

  const getFormattedData = () => {
    const downloadData =
      filteredData && filteredData.length ? filteredData : Data;
    // console.log(downloadData, 'downloading data...');
    // return [];
    let downloadDataFormatted = downloadData.map((row) => {
      console.log(row, 'downloading data row...');
      return {
        subColorName: row.subColorName,
        discription: row.discription,
        designColorCode: row.designColorCode,
        // color_family: row.color_family.colorName,
      };
    });

    console.log(downloadDataFormatted, 'downloading data 2...');
    return downloadDataFormatted;
  };

  return (
    <div>
      <h2>Color Master</h2>
      {/* Modal */}
      <Button onClick={showModal}>Color Master</Button>

      {/* Table Section */}
      <div className="table">
        {/* Filter search Section */}
        <div className="filterContainer">
          <div className="tableSearchSection">
            <Select
              defaultValue="all"
              className="filterScopeSelect"
              onChange={handleFilterScopeChange}
              options={filterOptionArray}
            />

            <Input
              placeholder="Enter Search Text"
              onChange={handleChangeSearch}
              type="text"
              allowClear
              value={searchText}
            ></Input>
            <Button onClick={resetTable}>Reset</Button>
            <Button style={{ backgroundColor: 'greenyellow', color: 'white' }}>
              <CSVLink
                data={getFormattedData()}
                filename={
                  'Color-Master-Records-list-' +
                  moment(new Date()).format('DD-MM-YYYY')
                }
              >
                Download
              </CSVLink>
            </Button>
          </div>
        </div>
        <Table
          dataSource={filteredData && filteredData.length ? filteredData : Data}
          columns={columns}
          onChange={handleTableChange}
          pagination={{ position: ['bottomCenter'] }}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: alreadySelectedRows,
            onChange: (keys) => {
              setAlreadySelectedRows(keys);
            },
            onSelect: (record) => {
              console.log(record, 'selected row ');
            },
            selections: [Table.SELECTION_NONE, Table.SELECTION_ALL],
          }}
        />
      </div>

      {/* Modal For Add Or Edit */}
      <Modal
        title="Add New Color"
        visible={isModalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <Card className="addProduct_page_card">
          <Form form={dataform} layout="vertical" onFinish={addNewRecord}>
            <Row gutter={[16, 16]}>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Color Name"
                  name="subColorName"
                  rules={[
                    {
                      required: true,
                      message: 'Color Name must be unique and is Required!',
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Color Name" />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Discription"
                  name="discription"
                  rules={[
                    {
                      message: 'discription is Required!',
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Discription" />
                </Form.Item>
              </Col>
              {/*
              // Hex Code for other use.
            <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Hex Code"
                  name="hexCode"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="hexCode" />
                </Form.Item>
              </Col>

                */}

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Design Color Code"
                  name="designColorCode"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Design Color Code" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Color Family/ Parent Color"
                  name="color_family"
                  hidden={true}
                  rules={[
                    {
                      message: 'Color Family is Required!',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    onSearch={() => {
                      //
                    }}
                    placeholder="Select "
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.trim().toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={colorFamilyListOptions}
                  />
                </Form.Item>
              </Col>

              <Form.Item label="Username" hidden={true} name="editId">
                <Input type="text" />
              </Form.Item>
            </Row>
            <Button
              className="addProduct_save_btn"
              htmlType="submit"
              type="primary"
              size="large"
            >
              {saving ? (
                <>
                  <Spin size="small" /> Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};
export default ColorMaster;
