export const coreProductStatusArray = [
    { object_status_no: 1, object_status: "active" },
    { object_status_no: 2, object_status: "wip" },
    { object_status_no: 3, object_status: "completed" },
    { object_status_no: 4, object_status: "confirmed" },
    { object_status_no: 5, object_status: "fabric_complete", hide: true },
    { object_status_no: 6, object_status: "techpack_complete" },
    { object_status_no: 7, object_status: "bookable" },
    { object_status_no: 8, object_status: "booked" },
    { object_status_no: 9, object_status: "receiving_due", hide: true },
    { object_status_no: 10, object_status: "receiving_pending", hide: true },
    { object_status_no: 11, object_status: "received", hide: true },
    { object_status_no: 12, object_status: "postponed", hide: true },
    { object_status_no: 13, object_status: "canceled", hide: true },
    { object_status_no: 14, object_status: "dropped", hide: true },
    { object_status_no: 15, object_status: "deleted", hide: true },
]

export const attributionCategoryOptionArray = [
    "semi-casual",
    "casual",
    'formal'
]
export const paymentModelOptionArray = [
    "cmt",
    "fob",
]

export const fabricLiabilityStatusArray = [
    "with-fabric-liability",
    "without-fabric-liability",
    "others",
]

export const patternOptionArray = [
    "check",
    "dobby",
    'twill'
]
export const knitWovenOptionArray = [
    "Circular Knit",
    "Flat Knit",
    "Woven",
    "Circular Knit + Flat Knit",
]

export const weightOptionArray = [
    "low-weight",
    "mid-weight",
    "high-weight",
]

export const freshnessOptionArray = [
    "New",
    "Injection",
    "Version",
    "Repeat",
    "Replenishment",
]

export const lifeOccasionOptionArray = [
    'Casual',
    'Evening',
    'Formal',
    'Occasion',
    'Semi Formal',
    'Semi Casual',
]

export const tnaStatusOptionArray = [
    'active',
    'inactive',
    'wip',
    'pending',
    'droped',
    'postponed',
    'complete',
    'confirmed',
]



export const toProperCase = (str) => {

    return str.replace(
        /\w*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
};




export default coreProductStatusArray;